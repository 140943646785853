import React, { Component } from 'react';
import {
    Col, Row, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';

const CONSULTORIA = [
    { nome: 'Agronegócio', image: require('../../images/agronegocio.jpg') },
    { nome: 'Atendimento ao Cliente', image: require('../../images/atendimento.jpg') },
    { nome: 'Empreendorismo', image: require('../../images/empreendorismo.png') },
    { nome: 'Finanças', image: require('../../images/financas.jpg') },
    { nome: 'Gerenciamento de Crise', image: require('../../images/crise.jpg') },
    { nome: 'Gestão de Equipes', image: require('../../images/gestao.png') },
    { nome: 'Hotelaria', image: require('../../images/hotelaria.png') },
    { nome: 'Inovação', image: require('../../images/inovacao.jpg') },
    { nome: 'Liderança', image: require('../../images/lideranca.jpg') },
    { nome: 'Marketing', image: require('../../images/marketing.png') },
    { nome: 'Mercado de Luxo', image: require('../../images/luxo.jpg') },
    { nome: 'Meio Ambiente', image: require('../../images/ambiente.jpg') },
    { nome: 'Psicologia', image: require('../../images/psicologia.jpg') },
    { nome: 'Saúde', image: require('../../images/saude.png') },
    { nome: 'Sustentabilidade', image: require('../../images/sustentabilidade.jpg') },
    { nome: 'Tecnologia', image: require('../../images/tecnologia.jpg') },
    { nome: 'Tecnologia da Informação', image: require('../../images/ti.jpg') },
    { nome: 'Segurança', image: require('../../images/seguranca.jpg') },
    { nome: 'Segurança da Informação', image: require('../../images/segurancainformacao.jpg') },
    { nome: 'Trabalho em Equipe', image: require('../../images/trabalhoequipe.png') },
    { nome: 'Turismo', image: require('../../images/turismo.jpg') },
    { nome: 'Varejo', image: require('../../images/varejo.jpg') },
    { nome: 'Vendas', image: require('../../images/vendas.jpg') },

]

class Servicos extends Component {

    render() {
        return (
            <div className="container">
                <Row>
                    {CONSULTORIA.map((parceiro, index) => {
                        return (
                            <Col key={index} style={{ marginTop: 30 }}>
                                <Card className='parceiro'>
                                    <CardImg top width="100%" height="130" src={parceiro.image} style={{ objectFit: 'cover' }} />
                                    <CardBody>
                                        <CardTitle>{parceiro.nome}</CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}

                </Row>
            </div>
        );

    }
}

export default Servicos;
