import React, { Component } from 'react';

class About extends Component {

    render() {
        return (
            <div className="container">
                <h1>Quem somos</h1>
                <p>
                    A Iniciativa 21 constitui-se em uma empresa especializada na geração de soluções de educação corporativa e gestão do conhecimento. Atuando nas áreas de Consultoria, Palestras, Coach, Treinamentos e organização de eventos, dispomos de ampla gama de soluções, perfeitamente adequadas às necessidades, objetivos e orçamentos de nossos clientes.
                </p>
                <p>
                    Nossa experiência profissional, com equipe formada por mais de 20 anos em função de gestão de empresas, dispõe da capacidade para compreender, analisar e propor soluções dentro das mais altas expectativas.
                </p>
                <p>
                    Atuando no modelo Speakers Bureau (banco de palestrantes), nossos clientes dispõem de uma ampla diversidade de pensadores contemporâneos, capazes de levantar e aprofundar os temas de relevância para sustentação e crescimento organizacional.
                </p>
                <p>
                    Todos os envolvidos nesse modelo de negócios só têm a ganhar com a economia de tempo e a segurança jurídica intermediada pela Iniciativa 21.
                </p>
                <p>
                    Intermediar a relação de tamanha responsabilidade, criando parcerias entre clientes, palestrantes e consultores só irá gerar frutos e se perpetuar com base no respeito mútuo, ética, transparência e comprometimento entre as partes.
                </p>
                <p>
                    Esses são os pilares e valores da Iniciativa 21.
                </p>
            </div>
        );

    }
}

export default About;
