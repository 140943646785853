import React, { Component } from 'react';
import { Document, Page, setOptions } from 'react-pdf';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';

const items = [
    {
        src: require('../../images/slide1.jpg'),
        altText: '',
        caption: ''
    },
    {
        src: require('../../images/slide2.jpg'),
        altText: '',
        caption: ''
    },
    {
        src: require('../../images/slide3.jpg'),
        altText: '',
        caption: ''
    }
];

class App extends Component {

    state = {
        width: 0
    }

    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    componentDidMount() {
        const width = this.container.clientWidth;
        this.setState({ width });
    }

    onDocumentLoad() {

    }

    renderLoader() {
        return (<div />)
    }

    renderDocument() {
        const { width } = this.state;
        if (width) {
            return (
                <Document
                    file="inicio.pdf"
                    onLoadSuccess={this.onDocumentLoad.bind(this)}
                    loading={this.renderLoader()}
                >
                    <Page pageNumber={2} width={width} />
                    <Page pageNumber={3} width={width} />
                    <Page pageNumber={4} width={width} />
                    <Page pageNumber={5} width={width} />
                    <Page pageNumber={6} width={width} />
                    <Page pageNumber={7} width={width} />
                    <Page pageNumber={8} width={width} />
                    <Page pageNumber={9} width={width} />
                    <Page pageNumber={10} width={width} />
                    <Page pageNumber={11} width={width} />
                    <Page pageNumber={12} width={width} />
                    <Page pageNumber={13} width={width} />
                    <Page pageNumber={14} width={width} />
                    <Page pageNumber={15} width={width} />
                    <Page pageNumber={16} width={width} />
                    <Page pageNumber={17} width={width} />
                    <Page pageNumber={18} width={width} />
                </Document>
            );
        }
    }

    render() {
        const { activeIndex } = this.state;

        const slides = items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <img src={item.src} alt={item.altText} width={this.state.width} />
                    <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
                </CarouselItem>
            );
        });

        return (
            <div style={{ flex: 1 }} ref={(e) => this.container = e}>
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                    <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
                {this.renderDocument()}
            </div>
        );
    }
}

export default App;
