import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

export default class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/"><img src={require('../../images/logo.png')} width="250" /></NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink href="/">Início</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/about/">Quem somos?</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/parceiros/">Parceiros</NavLink>
                        </NavItem>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Serviços
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem href="/servicos?tipo=consultoria">Consultoria</DropdownItem>
                                <DropdownItem href="/servicos?tipo=palestras">Palestras</DropdownItem>
                                <DropdownItem href="/servicos?tipo=treinamentos">Treinamentos</DropdownItem>
                                <DropdownItem href="/servicos?tipo=coach">Coach</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Eventos
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>O que fazemos</DropdownItem>
                                <DropdownItem>Cursos</DropdownItem>
                                <DropdownItem>Palestras</DropdownItem>
                                <DropdownItem>Treinamentos</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Calendários
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem href="/calendario?year=2018">2018</DropdownItem>
                                <DropdownItem href="/calendario?year=2019">2019</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Intituto Galilee
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem href="/instituto">O Instituto</DropdownItem>
                                <DropdownItem>Área de Atuação</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Outras Opções
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>Inscrição</DropdownItem>
                                <DropdownItem>Cursos Online</DropdownItem>
                                <DropdownItem>Blog</DropdownItem>
                                <DropdownItem>Galeria</DropdownItem>
                                <DropdownItem>Contatos</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}