import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav/Nav';
import Inicio from './containers/Inicio/Inicio';
import Galilee from './containers/Inicio/Galilee';
import About from './containers/About/About';
import Parceiros from './containers/Parceiros/Parceiros';
import Servicos from './containers/Servicos/Servicos';
import Calendario from './containers/Calendario/Calendario';
import { Document, Page, setOptions } from 'react-pdf';
import { BrowserRouter, Switch, Route, Router } from 'react-router-dom';

class App extends Component {

    state = {
        width: 1
    }

    componentDidMount() {
        setOptions({ workerSrc: '/pdf.worker.min.js', })
        const width = this.container.clientWidth;
        this.setState({ width });
    }
    render() {
        return (
            <div className="App">
                <Nav />
                <div className="centeredContainer" ref={(e) => this.container = e}>
                    <BrowserRouter ref={(r) => this.router = r}>
                        <Switch>
                            <Route path="/" exact={true} component={Inicio} />
                            <Route path="/instituto" exact={true} component={Galilee} />
                            <Route path="/about" component={About} />
                            <Route path="/parceiros" component={Parceiros} />
                            <Route path="/servicos" component={Servicos} />
                            <Route path="/calendario" component={Calendario} />
                        </Switch>
                    </ BrowserRouter>
                </div>
            </div>
        );
    }
}

export default App;
