import React, { Component } from 'react';
import {
    Col, Row, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';

const PARCEIROS = [
    { nome: 'STA Holding', cidade: 'São Paulo/SP', image: require('../../images/sta.jpg'), descricao: 'Desenvolver projetos CRM: uma estratégia que visa elevar lucros, otimizar processos, reduzir custos e os ciclos de vendas; Projetos CRM; Automação de marketing e vendas; Modelos de negócios para Contact Center' },
    { nome: 'TotalCare', cidade: 'São Paulo/SP', image: require('../../images/totalcare.jpg'), descricao: 'Aumentar a eficiência de atendimento e ganhar a fidelidade dos clientes, através das ações e estratégias de contact center; Pesquisas de mercado; Vendas / Atendimento / Cobranças; Programas de relacionamento e fidelidade' },
    { nome: 'InfMaster', cidade: 'Campinas/SP', image: require('../../images/infmaster.jpg'), descricao: 'Proteja seus dados com nossa solução de backup, se possui empresa de TI, conheça nosso modelo de revenda. NextCloud; FTP Backup; Assistência Remota; Hospedagem' },
    { nome: 'Cloudfence', cidade: 'São Paulo/SP', image: require('../../images/cloudfence.jpg'), descricao: 'A Cloudfence é um provedor de serviços gerenciados de segurança (MSSP) e através de nossas soluções, oferecemos aos clientes o que há de melhor em tecnologia de segurança da informação para a proteção e controle de seus dados no meio digital.' },
]

class Parceiros extends Component {

    render() {
        return (
            <div className="container">
                <Row>

                    {PARCEIROS.map((parceiro, index) => {
                        return (
                            <Col key={index}>
                                <Card className='parceiro'>
                                    <CardImg top width="100%" height="130" src={parceiro.image} style={{ objectFit: 'cover' }} />
                                    <CardBody>
                                        <CardTitle>{parceiro.nome}</CardTitle>
                                        <CardSubtitle>{parceiro.cidade}</CardSubtitle>
                                        <CardText>{parceiro.descricao}</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}

                </Row>
            </div>
        );

    }
}

export default Parceiros;
