import React, { Component } from 'react';
import { Document, Page, setOptions } from 'react-pdf';

class App extends Component {

    state = {
        width: 0
    }

    componentDidMount() {
        const width = this.container.clientWidth;
        this.setState({ width });
    }

    onDocumentLoad() {

    }

    renderLoader() {
        return (<div />)
    }

    renderDocument() {
        const { width } = this.state;
        if (width) {
            return (
                <Document
                    file="inicio.pdf"
                    onLoadSuccess={this.onDocumentLoad.bind(this)}
                    loading={this.renderLoader()}
                >
                    <Page pageNumber={2} width={width} />
                    <Page pageNumber={3} width={width} />
                    <Page pageNumber={4} width={width} />
                    <Page pageNumber={5} width={width} />
                    <Page pageNumber={6} width={width} />
                    <Page pageNumber={7} width={width} />
                    <Page pageNumber={8} width={width} />
                    <Page pageNumber={9} width={width} />
                    <Page pageNumber={10} width={width} />
                    <Page pageNumber={11} width={width} />
                    <Page pageNumber={12} width={width} />
                    <Page pageNumber={13} width={width} />
                    <Page pageNumber={14} width={width} />
                    <Page pageNumber={15} width={width} />
                    <Page pageNumber={16} width={width} />
                    <Page pageNumber={17} width={width} />
                    <Page pageNumber={18} width={width} />
                </Document>
            );
        }
    }

    render() {
        return (
            <div style={{ flex: 1 }} ref={(e) => this.container = e}>
                {this.renderDocument()}
            </div>
        );
    }
}

export default App;
