import React, { Component } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button, CardHeader, CardFooter
} from 'reactstrap';

const CALENDAR_2019 = [
    {
        dates: 'JANEIRO - FEVEREIRO',
        color: '#456290',
        options: [
            { date: '24 Janeiro - 04 Fevereiro', options: ['Management of Higher Education Institutes', 'Contemporary Public Administration Management'] }
        ]
    },
    {
        dates: 'FEVEREIRO - MARÇO',
        color: '#548C97',
        options: [
            { date: '26 Fevereiro- 11 Março', options: ['SMEs: Creating Growth through Entrepreneurship and Innovation', 'Transport: Planning, Development and Management', 'Advanced ICT Management: Trends and Strategies'] },
            { date: '28 Fevereiro - 11 Março', options: ['Banking Innovations and Financial Strategies'] }
        ]
    },
    {
        dates: 'MARÇO - ABRIL',
        color: '#377F78',
        options: [
            { date: '20 Março - 2 Abril', options: ['Agribusiness and Post-Harvest Management', 'Irrigated Agriculture in Times of Climate Change', 'Advanced Dairy Technology and Farm Management', 'Environmental Management', 'Integrated Water Resources Management'] }
        ]
    },
    {
        dates: 'MAIO - JUNHO',
        color: '#758F4E',
        options: [
            { date: '21 Maio - 3 Junho', options: ['Inovações na Agricultura para o Brasil - Português', 'Tecnologias de Irrigação e Processos de Cultivo - Português', 'Gestão de Sistemas de Saúde - Português', 'Gestión de Sistemas en Salud - Español', 'Innovación y Emprendimiento, la experiencia israelí - Español', 'Tecnologías de Irrigación y Procesos de Cultivo - Español', 'Agroindustria y Gestión Pos cosecha - Español', 'Producción Lechera de Alto Rendimiento - Español', 'Health Systems Management'] }
        ]
    },
    {
        dates: 'JUNHO - JULHO',
        color: '#9C9F42',
        options: [
            { date: '25 Junho - 8 Julho', options: ['Maritime and Port Security'] },
            { date: '27 Junho - 8 Julho', options: ['Management of Higher Education Institutes', 'Contemporary Public Administration Management', 'Human Resources Management', 'National Security', 'Diplomacy and National Security'] },
        ]
    },
    {
        dates: 'JULHO',
        color: '#E7DB50',
        options: [
            { date: '17 - 30 Julho', options: ['Project Management'] },
            { date: '18 - 30 Julho', options: ['Israel – Palestine Conflict: Understanding Both Sides'] },
        ]
    },
    {
        dates: 'AGOSTO - SETEMBRO',
        color: '#CE733B',
        options: [
            { date: '26 Agosto – 04 Setembro', options: ['International Executive DBA, Part 1 in France'] },
        ]
    },
    {
        dates: 'SETEMBRO',
        color: '#8D1E18',
        options: [
            { date: '10 - 23 Setembro', options: ['SMEs: Creating Growth through Entrepreneurship and Innovation', 'Transport: Planning, Development and Management', 'Monitoring and Evaluation', 'Agribusiness and Post-Harvest Management', 'Irrigated Agriculture in Times of Climate Change', 'Advanced Dairy Technology and Farm Management', 'Environmental Management', 'Integrated Water Resources Management'] },
            { date: '12 - 23 September', options: ['Banking Innovations and Financial Strategies'] }
        ]
    },
    {
        dates: 'NOVEMBRO',
        color: '#5B3225',
        options: [
            { date: '6 - 19 Novembro', options: ['Inovações na Agricultura para o Brasil - Português', 'Tecnologias de Irrigação e Processos de Cultivo - Português', 'Gestão de Sistemas de Saúde - Português', 'Gestión de Sistemas en Salud - Español', 'Innovación y Emprendimiento, la experiencia israelí - Español', 'Tecnologías de Irrigación y Procesos de Cultivo - Español', 'Agroindustria y Gestión Pos cosecha - Español', 'Producción Lechera de Alto Rendimiento - Español', 'Port Senior Management', 'Health Systems Management'] },
            { date: '10 -19 Novembro', options: ['PhD in Foreign Affairs and Diplomacy'] },
            { date: '12 -19 Novembro', options: ['International Executive DBA, Part 2 in Israel'] },
        ]
    },
    {
        dates: 'DEZEMBRO',
        color: '#7F7E6F',
        options: [
            { date: '03 - 16 Dezembro', options: ['Project Management'] },
            { date: '05 - 16 Dezembro', options: ['Human Resources Management', 'National Security', 'Diplomacy and National Security', 'Crisis and Mass Disaster Management', 'Gestión de Crisis en Situaciones de Emergencias -Español'] }
        ]
    },
    {
        dates: 'DEZEMBRO - JANEIRO',
        color: '#88A5AF',
        options: [
            { date: '26 Dezembro 2019 - 07 Janeiro 2020', options: ['One Land, Two Peoples and Three Religions'] },
        ]
    },
]

const CALENDAR_2018 = [
    {
        dates: 'JANEIRO - FEVEREIRO',
        color: '#456290',
        options: [
            { date: '24 Janeiro - 05 Fevereiro', options: ['Management of Higher Education Institutes', 'Contemporary Public Administration Management'] }
        ]
    },
    {
        dates: 'FEVEREIRO',
        color: '#548C97',
        options: [
            { date: '13 - 26 Fevereiro', options: ['SMEs: Creating Growth through Entrepreneurship and Innovation', 'Transport: Planning, Development and Management', 'Advanced ICT Management: Trends and Strategies'] },
            { date: '15 - 26 Fevereiro', options: ['Banking Innovations and Financial Strategies'] }
        ]
    },
    {
        dates: 'MARÇO',
        color: '#377F78',
        options: [
            { date: '13 - 26 Março', options: ['Agribusiness and Post-Harvest Management', 'Irrigated Agriculture in Times of Climate Change', 'Advanced Dairy Technology and Farm Management', 'Environmental Management', 'Integrated Water Resources Management'] },
        ]
    },
    {
        dates: 'MAIO',
        color: '#52A756',
        options: [
            { date: '01 - 14 Maio', options: ['Gestão de Sistemas de Saúde - Português', 'Gestión de Sistemas en Salud - Español', 'Innovación y Emprendimiento, la experiencia israelí - Español', 'Health Systems Management'] },
        ]
    },
    {
        dates: 'MAIO - JUNHO',
        color: '#9CBA4F',
        options: [
            { date: '29 Maio - 11 Junho', options: ['Tecnologias de Irrigação e Processos de Cultivo - Português', 'Inovações na Agricultura para o Brasil - Português', 'Tecnologías de Irrigación y Procesos de Cultivo - Español', 'Agroindustria y Gestión Pos cosecha - Español', 'Producción Lechera de Alto Rendimiento - Español', 'Maritime and Port Security'] },
            { date: '31 Maio - 11 Junho', options: ['National Security', 'Diplomacy and National Security', 'Human Resources Management'] }
        ]
    },
    {
        dates: 'JUNHO - JULHO',
        color: '#9C9F42',
        options: [
            { date: '28 Junho - 9 Julho', options: ['Management of Higher Education Institutes', 'Contemporary Public Administration Management'] },
        ]
    },
    {
        dates: 'JULHO',
        color: '#E7DB50',
        options: [
            { date: '17 - 30 Julho', options: ['Project Management'] },
            { date: '18 - 30 Julho', options: ['Israel – Palestine Conflict: Understanding Both Sides'] },
        ]
    },
    {
        dates: 'AGOSTO - SETEMBRO',
        color: '#CE733B',
        options: [
            { date: '27 Agosto – 05 Setembro', options: ['International Executive DBA, Part 1 in France'] },
        ]
    },
    {
        dates: 'OUTUBRO',
        color: '#8D1E18',
        options: [
            { date: '15 - 24 Outubro', options: ['PhD in Foreign Affairs and Diplomacy'] },
            { date: '16 - 29 Outubro', options: ['SMEs: Creating Growth through Entrepreneurship and Innovation', 'Transport: Planning, Development and Management', 'Agribusiness and Post-Harvest Management', 'Irrigated Agriculture in Times of Climate Change', 'Advanced Dairy Technology and Farm Management', 'Environmental Management', 'Integrated Water Resources Management'] },
            { date: '18 - 29 October', options: ['Banking Innovations and Financial Strategies'] },
        ]
    },
    {
        dates: 'NOVEMBRO',
        color: '#5B3225',
        options: [
            { date: '14 - 27 Novembro', options: ['Gestão de Sistemas de Saúde - Português', 'Gestión en Sistemas de Salud – Español', 'Innovación y Emprendimiento, la experiencia israelí - Español', 'Port Senior Management', 'Health Systems Management', 'Monitoring and Evaluation'] },
            { date: '19 - 27 Novembro', options: ['International Executive DBA, Part 2 in Israel'] },
        ]
    },
    {
        dates: 'DEZEMBRO',
        color: '#7F7E6F',
        options: [
            { date: '04 - 17 Dezembro', options: ['Tecnologias de Irrigação e Processos de Cultivo - Português', 'Inovações na Agricultura para o Brasil - Português', 'Tecnologías de Irrigación y Procesos de Cultivo - Español', 'Agroindustria y Gestión Pos cosecha - Español', 'Producción Lechera de Alto Rendimiento - Español', 'Project Management'] },
            { date: '06 - 17 Dezembro', options: ['Gestión de Crisis en Situaciones de Emergencias - Español', 'Crisis and Mass Disaster Management', 'National Security', 'Diplomacy and National Security', 'Human Resources Management'] },
        ]
    },
    {
        dates: 'DEZEMBRO - JANEIRO',
        color: '#88A5AF',
        options: [
            { date: '27 Dezembro 2018 - 08 Janeiro 2019', options: ['One Land, Two Peoples and Three Religions'] },
        ]
    },
]

class Calendario extends Component {

    state = {
        year: 0,
        calendar: []
    }

    componentDidMount() {
        if (this.props.location.search == "?year=2019") {
            this.setState({ year: 2019, calendar: CALENDAR_2019 });
        }
        if (this.props.location.search == "?year=2018") {
            this.setState({ year: 2018, calendar: CALENDAR_2018 });
        }
    }

    render() {
        return (
            <div className="container">
                <h1>Calendário de Cursos para {this.state.year}</h1>
                <VerticalTimeline>
                    {this.state.calendar.map((month, monthIndex) => {
                        return (
                            <VerticalTimelineElement
                                key={monthIndex}
                                className="vertical-timeline-element--work"
                                date={month.dates}
                                iconStyle={{ background: month.color, color: '#fff', width: 0, height: 0 }}
                                icon={<div />}
                            >
                                <Card>
                                    <CardHeader style={{ background: month.color, color: '#fff' }}>{month.dates}</CardHeader>
                                    <CardBody>
                                        {month.options.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <CardTitle style={{ marginTop: 10, color: month.color }}>{item.date}</CardTitle>
                                                    {item.options.map((event, eventIndex) => {
                                                        return (
                                                            <CardText key={eventIndex}>{event}</CardText>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            );
                                        })}
                                    </CardBody>
                                </Card>
                            </VerticalTimelineElement>
                        );
                    })}
                </VerticalTimeline>
            </div>
        );

    }
}

export default Calendario;
